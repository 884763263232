<template>
  <div id="slack-panel">
    <v-data-table
      ref="slackMessageTable"
      dense
      height="400px"
      :headers="headers"
      :items="slackMessages"
      item-key="client_msg_id"
      class="elevation-1"
      :fixed-header="fixedHeader"
      :items-per-page="itemsPerPage"
      hide-default-footer
      sort-by="ts"
    >
      <template v-slot:item.ts="{ item }">
        {{ item.ts | formatDecimalEpochs }}
      </template>
      <template v-slot:item.text="{ item }">
        {{ item.text | formatText }}
      </template>
    </v-data-table>
    <v-text-field
      v-model="messageToSend"
      @keydown="onSlackMessageFieldKeyDown"
      counter="255"
      hint="Type message to send"
      label="Send Slack Message"
      height="40px"
    ></v-text-field>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EmojiConverter from 'emoji-js'

const emojiConverter = new EmojiConverter()

export default {
  name: 'slack-panel',
  computed: {
    ...mapState({
      slackMessages: 'slackMessages'
    }),
    headers() {
      return [
        {
          text: 'Date and Time',
          align: 'start',
          sortable: false,
          value: 'ts'
        },
        {
          text: 'User',
          value: 'username'
        },
        { text: 'Message', value: 'text' }
      ]
    }
  },
  data: () => ({
    itemsPerPage: -1,
    fixedHeader: true,
    messageToSend: ''
  }),
  methods: {
    onSlackMessageFieldKeyDown(e) {
      if (e.key === 'Enter' && this.messageToSend.length > 0) {
        this.$store.dispatch('sendSlackMessage', this.messageToSend)
        this.messageToSend = ''
        e.target.focus()
        e.target.select()
      }
    }
  },
  mounted: function () {
    console.log('slack-panel mounted')
  },
  filters: {
    formatDecimalEpochs: function (decimalEpochString) {
      const epochMilliSeconds = Math.trunc(
        parseFloat(decimalEpochString) * 1000
      )
      const date = new Date(epochMilliSeconds)
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hours = date.getHours()
      if (hours < 10) {
        hours = '0' + hours
      }
      let minutes = date.getMinutes()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      const dateString = month + '/' + day + ' ' + hours + ':' + minutes
      return dateString
    },
    formatText: function (text) {
      const convertedText = emojiConverter.replace_colons(text)
      return convertedText
    }
  },
  watch: {
    slackMessages: function (newSlackMessages) {
      setTimeout(() => {
        const slackPanelElement = document.getElementById('slack-panel')
        if (slackPanelElement) {
          const scrollablePanel = slackPanelElement.children[0].children[0]
          if (scrollablePanel) {
            scrollablePanel.scrollTop = scrollablePanel.scrollHeight
          }
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
#slack-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>
