<template>
  <v-combobox
    v-if="loggedIn"
    dense
    solo
    hide-details="true"
    @change="setVessel"
    :items="availableVessels"
    item-text="name"
    :value="selectedVessel"
  ></v-combobox>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'vessel-select',
  computed: {
    ...mapState({
      loggedIn: 'loggedIn',
      availableVessels: 'availableVessels',
      selectedVessel: 'selectedVessel'
    })
  },
  methods: {
    setVessel(vessel) {
      this.$store.dispatch('setSelectedVessel', vessel)
    }
  }
}
</script>
<style scoped></style>
