<template>
  <v-container v-if="loggedIn">
    <v-row>
      <v-col cols="12">
        <zoom-meeting-panel />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <map-panel />
      </v-col>
      <v-col cols="6">
        <slack-panel />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <screen-grab-panel />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><data-configure-dialog-button /></v-col>
    </v-row>
    <v-row style="min-height: 800px">
      <v-col cols="3">
        <data-table />
      </v-col>
      <v-col cols="9">
        <data-panel />
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>
<script>
import { mapState } from 'vuex'
import ZoomMeetingPanel from './ZoomMeetingPanel.vue'
import MapPanel from './MapPanel.vue'
import SlackPanel from './SlackPanel.vue'
import ScreenGrabPanel from './ScreenGrabPanel.vue'
import DataConfigureDialogButton from './DataConfigureDialogButton.vue'
import DataTable from './DataTable.vue'
import DataPanel from './DataPanel.vue'

export default {
  components: {
    ZoomMeetingPanel,
    MapPanel,
    SlackPanel,
    ScreenGrabPanel,
    DataConfigureDialogButton,
    DataTable,
    DataPanel
  },
  computed: {
    ...mapState({
      loggedIn: 'loggedIn'
    })
  }
}
</script>
<style scoped></style>
