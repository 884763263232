<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <vessel-select />
      </div>

      <v-spacer></v-spacer>

      <span class="mr-2">
        <user-menu />
      </span>
    </v-app-bar>
    <v-dialog v-model="dialog" persistent max-width="275">
      <slack-dialog />
    </v-dialog>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import VesselSelect from './components/VesselSelect.vue'
import UserMenu from './components/UserMenu.vue'
import SlackDialog from './components/SlackDialog.vue'

export default {
  components: { VesselSelect, UserMenu, SlackDialog },
  name: 'App',
  computed: {
    ...mapState({
      loggedIn: 'loggedIn'
    }),
    dialog: function () {
      return !this.loggedIn
    }
  }
}
</script>
<style scoped></style>
