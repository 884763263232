<template>
  <div id="tp-plotly" ref="tp-plotly"></div>
</template>
<script>
import { mapState } from 'vuex'
import Plotly from 'plotly.js'

export default {
  name: 'data-panel',
  computed: {
    ...mapState({
      dmdata: 'dmdata',
      plotlyLayout: 'plotlyLayout'
    })
  },
  data: () => ({}),
  mounted: function () {
    Plotly.newPlot(this.$refs['tp-plotly'], this.dmdata, this.plotlyLayout, {
      responsive: true
    })
    setInterval(() => {
      this.refreshPlot()
    }, 30000)
  },
  methods: {
    refreshPlot: function () {
      Plotly.newPlot(this.$refs['tp-plotly'], this.dmdata, this.plotlyLayout, {
        responsive: true
      })
    }
  },
  watch: {
    dmdata: function () {
      Plotly.newPlot(this.$refs['tp-plotly'], this.dmdata, this.plotlyLayout, {
        responsive: true
      })
    }
  }
}
</script>
<style scoped>
#tp-plotly {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  left: 0 !important;
  right: 0;
}
</style>
