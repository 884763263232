<template>
  <v-data-table
    :headers="headers"
    :items="dmTableData"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideDefaultFooter"
    class="elevation-1"
  ></v-data-table>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'data-table',
  computed: {
    ...mapState({
      dmTableData: 'dmTableData'
    })
  },
  data() {
    return {
      itemsPerPage: -1,
      hideDefaultFooter: true,
      headers: [
        {
          text: 'Data Item',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Value', value: 'value' }
      ]
    }
  }
}
</script>
