<template>
  <h2>Logging in ...</h2>
</template>

<script>
export default {
  created() {
    // If all of these are present, go ahead and set them on the state
    this.$store.dispatch(
      'setUserByAccessToken',
      this.$route.query['access-token']
    )

    // Now route the user
    this.$router.push('/')
  }
}
</script>

<style></style>
