<template>
  <telepresence-portal />
</template>

<script>
import TelepresencePortal from '../components/TelepresencePortal.vue'

export default {
  name: 'Home',

  components: {
    TelepresencePortal
  }
}
</script>
