<template>
  <v-card id="container">
    <div id="mapContainer"></div>
    <div id="locationLabel">{{ locationLabelText }}</div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
  name: 'Map',
  data() {
    return {
      map: null,
      shipPolyline: null,
      rovPolyLine: null
    }
  },
  computed: {
    ...mapState({
      shipTrack: 'shipTrack',
      rovTrack: 'rovTrack',
      latestShipLocation: 'latestShipLocation',
      latestROVDepth: 'latestROVDepth'
    }),
    locationLabelText() {
      let shipLat = '--'
      if (this.latestShipLocation && this.latestShipLocation[0]) {
        shipLat = this.latestShipLocation[0].toFixed(3)
      }
      let shipLon = '--'
      if (this.latestShipLocation && this.latestShipLocation[1]) {
        shipLon = this.latestShipLocation[1].toFixed(3)
      }
      let rovDepth = '-- m'
      if (this.latestROVDepth) {
        rovDepth = Math.trunc(this.latestROVDepth) + ' m'
      }
      return `${shipLat},${shipLon} (${rovDepth})`
    }
  },
  methods: {
    setupLeafletMap: function () {
      // Create the Leaflet map and attach to the leaflet box component
      // The base SRS will be 3857 as that is the most common
      if (!this.map) {
        console.log('Going to create new map')
        this.map = L.map('mapContainer', {
          minZoom: 2,
          maxZoom: 25
        }).setView([36.79829, -122.15], 11)

        // Add a layer that is the ESRI ocean basemap
        L.tileLayer(
          'http://services.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}',
          {
            id: 'esrioceanbasemap',
            maxNativeZoom: 13,
            maxZoom: 25
          }
        ).addTo(this.map)
      }
    }
  },
  mounted() {
    this.setupLeafletMap()
  },
  watch: {
    shipTrack: function () {
      console.log('shiptrack changed')
      if (this.shipPolyline) {
        this.map.removeLayer(this.shipPolyline)
        this.shipPolyline = null
      }
      if (this.shipTrack.length > 0) {
        this.shipPolyline = L.polyline(this.shipTrack, {
          color: 'red',
          weight: 3,
          smoothFactor: 1
        })
        if (this.map) {
          this.shipPolyline.addTo(this.map)
          // Move the view to be centered on the latest location of the ship
          this.map.setView(this.shipTrack[this.shipTrack.length - 1])
        }
      }
    },
    rovTrack: function () {
      if (this.rovPolyLine) {
        this.map.removeLayer(this.rovPolyLine)
        this.rovPolyLine = null
      }
      this.rovPolyLine = L.polyline(this.rovTrack, {
        color: 'black',
        weight: 2,
        smoothFactor: 1
      })
      console.log('new rov polyline', this.rovPolyLine)
      if (this.map) {
        this.rovPolyLine.addTo(this.map)
      }
    }
  }
}
</script>

<style scoped>
#container {
  position: relative;
  width: 100%;
  height: 100%;
}
#mapContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
#locationLabel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 1em;
  color: white;
  background-color: black;
  padding: 0.4em;
  opacity: 0.7;
}
</style>
