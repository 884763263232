// Import necessary libraries for the store
import Vue from 'vue'
import Vuex from 'vuex'
import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache
} from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'
import { split } from '@apollo/client'

// import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

// Tell Vue to use Vuex for the store
Vue.use(Vuex)

// Create a placeholder for the Apollo client
let apolloClient

// A timer that is used to refresh screen grabs every 30 seconds
let screenGrabTimeout

// This is a timer that cleans out any data older than 7 days
setInterval(() => {
  store.dispatch('cleanOutOldData')
}, 1000 * 60)

// The name of the server that will be used to connect to web sockets
const webSocketServer = location.host

// This is the query to look for a user matching a specific access token
const USER = gql`
  query User {
    user {
      id
      username
      email
      picture_url
      access_token
      is_admin
    }
  }
`
// This is the query to grab the URL that the user should be redirected to for logins
const LOGIN_URL = gql`
  query LoginUrl {
    slackLoginUrl
  }
`

// This is the query to grab the information about all vessels
const ALL_VESSELS_QUERY = gql`
  query AllVessels {
    vessels {
      id
      name
      icon_url
      picture_url
      screengrab_subdir
      rov_name
      rov_icon_url
      zoom_meeting_number
      zoom_meeting_api_key
      zoom_meeting_password
      slack_channel
    }
  }
`

// This is the query to get the list of screengrabs for a specific vessel
const SCREENGRABS = gql`
  query Screengrabs($screengrabSubdir: String!) {
    screenGrabs(screengrab_subdir: $screengrabSubdir)
  }
`

// The graphQL method to log the user out of the server
const LOGOUT = gql`
  mutation Logout($accessToken: String!) {
    logout(access_token: $accessToken)
  }
`

// This is the mutation to update a vessel on the server
const UPDATE_VESSEL = gql`
  mutation UpdateVessel(
    $id: String!
    $name: String
    $iconUrl: String
    $pictureUrl: String
    $screengrabSubdir: String
    $rovName: String
    $rovIconUrl: String
    $zoomMeetingApiKey: String
    $zoomMeetingNumber: String
    $zoomMeetingPassword: String
    $slackChannel: String
  ) {
    updateVessel(
      id: $id
      name: $name
      icon_url: $iconUrl
      picture_url: $pictureUrl
      screengrab_subdir: $screengrabSubdir
      rov_name: $rovName
      rov_icon_url: $rovIconUrl
      zoom_meeting_api_key: $zoomMeetingApiKey
      zoom_meeting_number: $zoomMeetingNumber
      zoom_meeting_password: $zoomMeetingPassword
      slack_channel: $slackChannel
    ) {
      id
    }
  }
`

const VESSEL_UPDATED = gql`
  subscription VesselUpdated {
    vesselUpdated {
      id
      name
      icon_url
      picture_url
      screengrab_subdir
      rov_name
      rov_icon_url
      zoom_meeting_number
      zoom_meeting_api_key
      zoom_meeting_password
      slack_channel
    }
  }
`

// This query grabs all the data items names that are available for a particular vessel
const DATA_ITEM_NAMES_BY_VESSEL = gql`
  query DataItemNamesByVessel($vessel: String!) {
    dataItemNamesByVessel(vessel: $vessel) {
      id
      vessel
      server
      item_name
      alias
    }
  }
`

const LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME = gql`
  query LastXNumberOfHoursOfDataItemsByVesselServerItemName(
    $vesselName: String!
    $server: String!
    $itemName: String!
    $numberOfHours: Int!
  ) {
    lastXNumberOfHoursOfDataItemsByVesselServerItemName(
      vessel: $vesselName
      server: $server
      item_name: $itemName
      number_of_hours: $numberOfHours
    ) {
      id
      vessel
      server
      item_name
      epoch_seconds
      value
    }
  }
`

const LAST_X_MESSAGES_FROM_SLACK_CHANNEL = gql`
  query LastXMessagesFromSlackChannel(
    $channel: String!
    $numberOfMessages: Int!
  ) {
    lastXMessagesFromSlackChannel(
      channel: $channel
      number_of_messages: $numberOfMessages
    ) {
      client_msg_id
      type
      text
      user
      username
      ts
      team
      channel
      event_ts
      channel_type
    }
  }
`

const DATA_ITEM_UPDATED = gql`
  subscription DataItemUpdated {
    dataItemUpdated {
      id
      vessel
      server
      item_name
      epoch_seconds
      value
    }
  }
`

const SEND_SLACK_MESSAGE = gql`
  mutation SendSlackMessage($message: String!, $channel: String!) {
    sendSlackMessage(message: $message, channel: $channel)
  }
`

const SLACK_MESSAGE_SENT = gql`
  subscription SlackMessageSent {
    slackMessageSent {
      client_msg_id
      type
      text
      user
      username
      ts
      team
      channel
      event_ts
      channel_type
    }
  }
`

// This function looks for a user token in local storage and creates an apollo client
// with it
function buildApolloClient() {
  // First thing to look for is a 'access-token' in local storage, if we
  // don't have one, it's likely the user hasn't logged in.
  const token = localStorage.getItem('access-token')

  // Create an HTTP Link
  const httpLink = createHttpLink({
    uri: '/api/v1/graphql'
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: 'wss://' + webSocketServer + '/api/v1/graphql/_ws_/'
    })
  )

  // Create an authentication link that attaches the token
  const authLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })

    // Call the next link in the middleware chain.
    return forward(operation)
  })

  // Create a split link
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    authLink.concat(httpLink)
  )

  // Now create the client
  apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  })
  console.log(apolloClient)
  // Subscribe to vessel information updates
  const vesselObserver = apolloClient.subscribe({
    query: VESSEL_UPDATED
  })
  vesselObserver.subscribe({
    next(data) {
      store.dispatch('handleVesselUpdatedFromServer', data.data.vesselUpdated)
    },
    error(error) {
      console.error('Error from vessel updated subscription: ')
      console.error(error)
    }
  })
  // Subscribe to data item updates
  const dataItemObserver = apolloClient.subscribe({
    query: DATA_ITEM_UPDATED
  })
  dataItemObserver.subscribe({
    next(data) {
      store.dispatch(
        'handleDataItemUpdatedFromServer',
        data.data.dataItemUpdated
      )
    },
    error(error) {
      console.error('Error from data item updated subscription: ')
      console.error(error)
    }
  })

  // Subscribe to data item updates
  const slackMessageObserver = apolloClient.subscribe({
    query: SLACK_MESSAGE_SENT
  })
  slackMessageObserver.subscribe({
    next(data) {
      store.dispatch(
        'handleSlackMessageSentFromServer',
        data.data.slackMessageSent
      )
    },
    error(error) {
      console.error('Error from slack message sent subscription: ')
      console.error(error)
    }
  })
}

// A function that looks to see if there is an 'auth_token' in the local storage and if
// there is, creates an Apollo client to use it to communicate with the server
function checkForApolloClient() {
  // Let's make sure there is no existing apollo client
  if (!apolloClient) {
    buildApolloClient()
  }
}

// Let's create the new store
const store = new Vuex.Store({
  state: {
    loginURL: null,
    loggedIn: false,
    accessToken: null,
    user: {},
    availableVessels: [],
    selectedVessel: {
      id: '',
      name: ''
    },
    shipTrack: [],
    rovTrack: [],
    locationCaches: {
      ship: {
        lat: null,
        lon: null
      },
      rov: {
        lat: null,
        lon: null
      }
    },
    latestShipLocation: [],
    latestROVLocation: [],
    latestROVDepth: 0,
    screengrabs: [],
    availableDataItems: [],
    dmdata: [],
    dmTableData: [],
    slackMessages: [],
    plotlyLayout: {
      autosize: true,
      showlegend: true,
      legend: {
        x: 0,
        xanchor: 'left',
        y: 1
      },
      title: 'Data Plots',
      xaxis: {
        type: 'date'
      }
    }
  },
  mutations: {
    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn
    },

    SET_LOGIN_URL(state, loginURL) {
      state.loginURL = loginURL
      console.log('loginURL: ' + state.loginURL)
    },

    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_AVAILABLE_VESSELS: function (state, vesselArray) {
      state.availableVessels = vesselArray
    },

    SET_SELECTED_VESSEL(state, vessel) {
      state.selectedVessel = vessel
    },

    SET_SCREENGRABS: function (state, screengrabUrlArray) {
      state.screengrabs = screengrabUrlArray
    },

    GET_SHIPTRACK: function (state) {
      if (apolloClient && state.selectedVessel.id) {
        const tempTrack = {}

        // state.shipTrack.properties.name = state.selectedVessel.name
        // state.shipTrack.properties.popupContent =
        //   'GPS Track for the ' + state.selectedVessel.name

        apolloClient
          .query({
            query:
              LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME,
            variables: {
              vesselName: state.selectedVessel.name,
              server: 'shipnav',
              itemName: 'SHIP.GPS.LAT',
              numberOfHours: 24
            },
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            res.data.lastXNumberOfHoursOfDataItemsByVesselServerItemName.forEach(
              (dataItem) => {
                tempTrack[dataItem.epoch_seconds] = dataItem.value
              }
            )
            // Now look up longitude
            apolloClient
              .query({
                query:
                  LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME,
                variables: {
                  vesselName: state.selectedVessel.name,
                  server: 'shipnav',
                  itemName: 'SHIP.GPS.LON',
                  numberOfHours: 24
                },
                fetchPolicy: 'network-only'
              })
              .then((res) => {
                res.data.lastXNumberOfHoursOfDataItemsByVesselServerItemName.forEach(
                  (dataItem) => {
                    if (tempTrack[dataItem.epoch_seconds]) {
                      state.shipTrack.push([
                        tempTrack[dataItem.epoch_seconds],
                        dataItem.value
                      ])
                    }
                  }
                )
                state.latestShipLocation =
                  state.shipTrack[state.shipTrack.length - 1]
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    GET_ROVTRACK: function (state) {
      if (apolloClient && state.selectedVessel.id) {
        const tempROVTrack = {}

        apolloClient
          .query({
            query:
              LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME,
            variables: {
              vesselName: state.selectedVessel.name,
              server: 'shipnav',
              itemName: 'ROV.POSITION.LAT',
              numberOfHours: 24
            },
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            res.data.lastXNumberOfHoursOfDataItemsByVesselServerItemName.forEach(
              (dataItem) => {
                tempROVTrack[dataItem.epoch_seconds] = dataItem.value
              }
            )
            // Now look up longitude
            apolloClient
              .query({
                query:
                  LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME,
                variables: {
                  vesselName: state.selectedVessel.name,
                  server: 'shipnav',
                  itemName: 'ROV.POSITION.LON',
                  numberOfHours: 24
                },
                fetchPolicy: 'network-only'
              })
              .then((res) => {
                res.data.lastXNumberOfHoursOfDataItemsByVesselServerItemName.forEach(
                  (dataItem) => {
                    if (tempROVTrack[dataItem.epoch_seconds]) {
                      state.rovTrack.push([
                        tempROVTrack[dataItem.epoch_seconds],
                        dataItem.value
                      ])
                    }
                  }
                )
                state.latestROVLocation =
                  state.rovTrack[state.rovTrack.length - 1]
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    GET_X_MESSAGES_FROM_SLACK_CHANNEL: function (state, numberOfMessages) {
      console.log(
        'GET_X_MESSAGES_FROM_SLACK_CHANNEL called with ' +
          numberOfMessages +
          ' from channel ' +
          state.selectedVessel.slack_channel
      )
      if (apolloClient && state.selectedVessel.slack_channel) {
        apolloClient
          .query({
            query: LAST_X_MESSAGES_FROM_SLACK_CHANNEL,
            variables: {
              channel: state.selectedVessel.slack_channel,
              numberOfMessages: numberOfMessages
            },
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            console.log('Got last ' + numberOfMessages + ' messages from slack')
            console.log(res.data.lastXMessagesFromSlackChannel)
            res.data.lastXMessagesFromSlackChannel.forEach((message) => {
              state.slackMessages.push(message)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },

    UPDATE_DATA_ITEM: function (state, dataItem) {
      // This means the store received an updated data item, so we need to
      // loop over the dmdata (the selected data items) and if vessel, server
      // and item name match, we need to append the timeseries data point
      state.dmdata.forEach((dataSeries) => {
        if (
          dataSeries.vessel === dataItem.vessel &&
          dataSeries.server === dataItem.server &&
          dataSeries.item_name === dataItem.item_name
        ) {
          dataSeries.x.push(new Date(dataItem.epoch_seconds * 1000))
          dataSeries.y.push(dataItem.value)
        }
      })

      // Now loop over the data table to see if it needs updating
      state.dmTableData.forEach((dataTableRow) => {
        if (
          dataTableRow.vessel === dataItem.vessel &&
          dataTableRow.server === dataItem.server &&
          dataTableRow.item_name === dataItem.item_name
        ) {
          dataTableRow.value = dataItem.value
        }
      })

      // See if the data is some kind of location
      if (
        dataItem.vessel === state.selectedVessel.name &&
        (dataItem.item_name === 'SHIP.GPS.LAT' ||
          dataItem.item_name === 'SHIP.GPS.LON' ||
          dataItem.item_name === 'ROV.POSITION.LAT' ||
          dataItem.item_name === 'ROV.POSITION.LON' ||
          dataItem.item_name === 'ROV.MBARI.DEPTH')
      ) {
        // If it's the depth, just set the latest
        if (dataItem.item_name === 'ROV.MBARI.DEPTH') {
          state.latestROVDepth = dataItem.value
        }

        // Set the value
        if (
          dataItem.item_name === 'SHIP.GPS.LAT' &&
          dataItem.server !== 'rovctd'
        ) {
          state.locationCaches.ship.lat = dataItem.value
        }
        if (
          dataItem.item_name === 'SHIP.GPS.LON' &&
          dataItem.server !== 'rovctd'
        ) {
          state.locationCaches.ship.lon = dataItem.value
        }
        if (dataItem.item_name === 'ROV.POSITION.LAT') {
          state.locationCaches.rov.lat = dataItem.value
        }
        if (dataItem.item_name === 'ROV.POSITION.LON') {
          state.locationCaches.rov.lon = dataItem.value
        }

        // Now look at the caches and if both lat/lon exist, add it to the location track and set the latest location, then clear the cache
        if (state.locationCaches.ship.lat && state.locationCaches.ship.lon) {
          state.shipTrack.push([
            state.locationCaches.ship.lat,
            state.locationCaches.ship.lon
          ])
          state.shipTrack.shift()
          state.latestShipLocation = [
            state.locationCaches.ship.lat,
            state.locationCaches.ship.lon
          ]
          state.locationCaches.ship.lat = null
          state.locationCaches.ship.lon = null
        }
        if (state.locationCaches.rov.lat && state.locationCaches.rov.lon) {
          state.rovTrack.push([
            state.locationCaches.rov.lat,
            state.locationCaches.rov.lon
          ])
          state.rovTrack.shift()
          state.latestROVLocation = [
            state.locationCaches.rov.lat,
            state.locationCaches.rov.lon
          ]
          state.locationCaches.rov.lat = null
          state.locationCaches.rov.lon = null
        }
      }
    },

    CLEAN_OUT_OLD_DATA: function (state) {
      const now = new Date()
      const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)
      const oneDayAgoTimestamp = oneDayAgo.getTime()
      state.dmdata.forEach((dataSeries) => {
        // Remove any data points that are older than 7 days
        if (dataSeries.x.length > 0) {
          while (dataSeries.x[0].getTime() < oneDayAgoTimestamp) {
            console.log('Removing data point from data series')
            console.log(dataSeries.x[0])
            dataSeries.x.shift()
            dataSeries.y.shift()
          }
        }
      })
    },

    CLEAR_DATA: function (state) {
      while (state.shipTrack.length > 0) {
        state.shipTrack.pop()
      }
      while (state.rovTrack.length > 0) {
        state.rovTrack.pop()
      }
      state.locationCaches.ship.lat = null
      state.locationCaches.ship.lon = null
      state.locationCaches.rov.lat = null
      state.locationCaches.rov.lon = null
      state.latestShipLocation = []
      state.latestROVLocation = []
      state.latestROVDepth = 0
      while (state.screengrabs.length > 0) {
        state.screengrabs.pop()
      }
      while (state.availableDataItems.length > 0) {
        state.availableDataItems.pop()
      }
      while (state.dmdata.length > 0) {
        state.dmdata.pop()
      }
      while (state.dmTableData.length > 0) {
        state.dmTableData.pop()
      }
      while (state.slackMessages.length > 0) {
        state.slackMessages.pop()
      }
    },

    ADD_SLACK_MESSAGE: function (state, slackMessage) {
      console.log('Adding slack message to store')
      console.log(slackMessage)
      console.log(JSON.stringify(slackMessage))
      if (
        state.slackMessages.find((message) => {
          return message.client_msg_id === slackMessage.client_msg_id
        })
      ) {
        console.log('Message already exists')
      } else {
        state.slackMessages.push(slackMessage)
      }
    },

    UPDATE_VESSEL: function (state, vessel) {
      // Loop through the vessels in the store and replace the one that was updated
      state.availableVessels.forEach((v, i) => {
        if (v.id === vessel.id) {
          state.availableVessels[i] = vessel
        }
      })
      // And update the selected vessel if it was updated
      if (state.selectedVessel.id === vessel.id) {
        state.selectedVessel = vessel
      }
    },

    LOGOUT: function (state) {
      // Log the user out of the server
      console.log('Will logout using access token ' + state.accessToken)
      if (apolloClient) {
        apolloClient
          .mutate({
            mutation: LOGOUT,
            variables: {
              accessToken: state.accessToken
            }
          })
          .then((res) => {
            console.log('Logout successful')
            // Remove information from local storage
            localStorage.clear()
            state.loggedIn = false
            state.accessToken = null
            state.user = {}
            state.selectedVessel = {}
            state.availableVessels = []
          })
          .catch((error) => {
            console.error('Error trying to logout user from the server:')
            console.error(error)
          })
      }
    },

    SET_AVAILABLE_DATA_ITEM_NAMES: function (state, dataItemNameArray) {
      state.availableDataItems = dataItemNameArray
    },

    SYNC_DATA_WITH_TABLE_AND_CHART: function (state) {
      console.log('Syncing data with table and chart')
      // Loop over each available data item
      state.availableDataItems.forEach((availableDataItem) => {
        // Check to see if the item is supposed to be in the table
        if (availableDataItem.inTable) {
          // A flag to indicate if the item is in the table
          let alreadyInTable = false
          // Check to see if the data has been added to the table
          state.dmTableData.forEach((dataItem) => {
            if (dataItem.id === availableDataItem.id) {
              alreadyInTable = true
            }
          })
          // If the item is not in the table, add it
          if (!alreadyInTable) {
            state.dmTableData.push({
              id: availableDataItem.id,
              vessel: availableDataItem.vessel,
              server: availableDataItem.server,
              item_name: availableDataItem.item_name,
              name:
                availableDataItem.item_name +
                ' (' +
                availableDataItem.server +
                ')',
              value: '---'
            })
          }
        } else {
          // If the item is not supposed to be in the table, remove it
          state.dmTableData = state.dmTableData.filter(
            (dataItem) => dataItem.id !== availableDataItem.id
          )
        }

        // Check to see if the item is supposed to be on the chart
        if (availableDataItem.onChart) {
          // A flag to indicate if it was already added
          let alreadyAdded = false
          // Check to see if the data has been added to the chart
          state.dmdata.forEach((dataSeries) => {
            if (dataSeries.id === availableDataItem.id) {
              // It's already there
              alreadyAdded = true
            }
          })
          // If it's not already there, add it
          if (!alreadyAdded) {
            // Now grab the data from the last 24 hours
            if (apolloClient) {
              apolloClient
                .query({
                  query:
                    LAST_X_NUMBER_OF_HOURS_OF_DATA_ITEMS_BY_VESSEL_SERVER_ITEM_NAME,
                  variables: {
                    vesselName: availableDataItem.vessel,
                    server: availableDataItem.server,
                    itemName: availableDataItem.item_name,
                    numberOfHours: 24
                  },
                  fetchPolicy: 'network-only'
                })
                .then((res) => {
                  // Create a object to hold the data series
                  const dataSeries = {
                    id: availableDataItem.id,
                    vessel: availableDataItem.vessel,
                    server: availableDataItem.server,
                    item_name: availableDataItem.item_name,
                    x: [],
                    y: [],
                    type: 'scatter',
                    name:
                      availableDataItem.item_name +
                      ' (' +
                      availableDataItem.server +
                      ')'
                  }

                  // Check to make sure something came back
                  let lastDate = null
                  if (res && res.data && res.data) {
                    res.data.lastXNumberOfHoursOfDataItemsByVesselServerItemName.forEach(
                      (dataObject) => {
                        lastDate = new Date(dataObject.epoch_seconds * 1000)
                        dataSeries.x.push(
                          new Date(dataObject.epoch_seconds * 1000)
                        )
                        dataSeries.y.push(dataObject.value)
                      }
                    )
                  }
                  console.log('Date of last data item: ' + lastDate)

                  // // First, let's see how many data series we have already
                  // const numberOfDataSeries = state.dmdata.length

                  // // Add the name of the y axis to the data series based on the size of the data series
                  // if (numberOfDataSeries > 0) {
                  //   dataSeries.yaxis = 'y' + (numberOfDataSeries + 1)
                  // }

                  // // Create a yAxis object to match
                  // const yAxis = {
                  //   title: availableDataItem.item_name,
                  //   autorange: true,
                  //   type: 'linear'
                  // }

                  // // Now, let's create a new yAxis named based on the number of data series
                  // let yAxisName = 'yaxis'
                  // if (numberOfDataSeries > 0) {
                  //   yAxisName = 'yaxis' + (numberOfDataSeries + 1)
                  //   yAxis.overlaying = 'y'
                  // }
                  // state.plotlyLayout[yAxisName] = yAxis

                  // // Add the data series to the data array
                  state.dmdata.push(dataSeries)
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          }
        } else {
          // Since it's not supposed to be on the chart, check to see if it's already there and remove it if it is
          state.dmdata.forEach((dataSeries, i) => {
            if (dataSeries.id === availableDataItem.id) {
              console.log('Removing data item from chart')
              state.dmdata.splice(i, 1)
            }
          })
        }
      })
    }
  },
  actions: {
    // This method should just be called once at startup to get things synchronized
    // between local storage and the remote server
    initializeStore({ commit, dispatch }) {
      // Make sure there is an apollo client if an auth_token already exists
      checkForApolloClient()

      // Grab the login URL from the server
      apolloClient
        .query({
          query: LOGIN_URL
        })
        .then((res) => {
          // Check to make sure something came back
          if (res && res.data && res.data.slackLoginUrl) {
            commit('SET_LOGIN_URL', res.data.slackLoginUrl)
          } else {
            console.error('No login URL returned from server')
          }
        })
        .catch((error) => {
          console.error('Error getting login URL from server:')
          console.error(error)
        })

      // If there is an access-token in local store, pull it into the Vuex store
      if (localStorage.getItem('access-token')) {
        commit('SET_ACCESS_TOKEN', localStorage.getItem('access-token'))

        // Check to see if the user was stored locally (it should if there is an access token)
        if (localStorage.getItem('loggedInUser')) {
          commit('SET_USER', JSON.parse(localStorage.getItem('loggedInUser')))
          commit('SET_LOGGED_IN', true)
        }

        // Grab the list of vessels from the server
        dispatch('loadAvailableVessels')

        // Now, check to see if the user previously had selected a specific vessel
        if (localStorage.getItem('selectedVessel')) {
          dispatch(
            'setSelectedVessel',
            JSON.parse(localStorage.getItem('selectedVessel'))
          )
        }
      }
    },

    // This is a method that takes in an access token and sets the local user associated with
    // that token
    setUserByAccessToken({ commit, dispatch }, accessToken) {
      // First, store the access token locally
      localStorage.setItem('access-token', accessToken)

      // Now, set the access token in the Vuex store
      commit('SET_ACCESS_TOKEN', accessToken)

      // Now, rebuild the apollo client with the new access token
      buildApolloClient()

      // Query the GraphQL interface to look for user matching the incoming token
      apolloClient
        .query({
          query: USER,
          fetchPolicy: 'network-only'
        })
        .then((res) => {
          // Check to make sure something came back
          if (res && res.data && res.data.user) {
            commit('SET_USER', res.data.user)
            commit('SET_LOGGED_IN', true)
            // Now, store the user locally
            localStorage.setItem('loggedInUser', JSON.stringify(res.data.user))

            // Load the list of vessels now that we have a user logged in
            dispatch('loadAvailableVessels')
          } else {
            console.error('No user found for access token')
          }
        })
        .catch((error) => {
          console.error('Error getting user from server:')
          console.error(error)
        })
    },
    logout({ commit }) {
      // Remove information from Vuex store
      commit('LOGOUT')
      commit('CLEAR_DATA')

      // Cancel any timers
      if (screenGrabTimeout) {
        clearTimeout(screenGrabTimeout)
      }
    },
    // This calls the server to get the list of available vessels and sets them in the store
    loadAvailableVessels({ commit }) {
      // Make sure we have an apollo client
      if (apolloClient) {
        // Grab the list of available vessels from the server and store them
        apolloClient
          .query({
            query: ALL_VESSELS_QUERY,
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            // Create an empty array
            const vesselArray = []

            // Check to make sure something came back
            if (res && res.data && res.data.vessels) {
              res.data.vessels.forEach((vessel) => {
                vesselArray.push(vessel)
              })
              // Now set on the state
              commit('SET_AVAILABLE_VESSELS', vesselArray)
            }
          })
      }
    },
    // This method sets the vessel that is selected in the store
    setSelectedVessel({ commit, dispatch }, vessel) {
      // Commit the selected vessel to the store
      commit('SET_SELECTED_VESSEL', vessel)

      // Now, store the selected vessel locally
      localStorage.setItem('selectedVessel', JSON.stringify(vessel))

      // Now, load the screengrab URLs for the selected vessel and set up a timer to reload them
      dispatch('loadScreengrabsForSelectedVessel')

      // Now, load the list of available data items for the selected vessel
      dispatch('loadAvailableDataItemsForSelectedVessel')

      // Clear any data that was previously loaded
      dispatch('clearData')

      // Load ship and ROV tracks
      commit('GET_SHIPTRACK')
      commit('GET_ROVTRACK')
      commit('GET_X_MESSAGES_FROM_SLACK_CHANNEL', 50)
    },
    // This method looks to see if a vessel is selected and if so, loads the screengrab URLs for that vessel
    // and then sets up a timer to reload them
    loadScreengrabsForSelectedVessel({ state, commit, dispatch }) {
      // Check to see if there is a selected vessel and an apollo client
      if (apolloClient && state.selectedVessel) {
        // Create new date to serve as a 'cache buster'
        const cacheBuster = +new Date()

        // Now grab the list of screengrab URLs from the server
        apolloClient
          .query({
            query: SCREENGRABS,
            variables: {
              screengrabSubdir: state.selectedVessel.screengrab_subdir
            },
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            // Create a temporary array
            const tempUrlArray = []

            // Check to make sure something came back
            if (res && res.data && res.data.screenGrabs) {
              res.data.screenGrabs.forEach((screenGrabUrl) => {
                tempUrlArray.push(screenGrabUrl + '?cb=' + cacheBuster)
              })
              commit('SET_SCREENGRABS', tempUrlArray)
            }

            // If there is a timer already, cancel it so we don't have multiple timers running
            if (screenGrabTimeout) {
              clearTimeout(screenGrabTimeout)
              screenGrabTimeout = null
            }
            // Set a timer that refreshes the list of screengrabs every 30 seconds
            screenGrabTimeout = setTimeout(() => {
              dispatch('loadScreengrabsForSelectedVessel')
            }, 30000)
          })
          .catch((error) => {
            console.error('Error getting screengrabs from server:')
            console.error(error)
          })
      }
    },
    loadAvailableDataItemsForSelectedVessel({ state, commit }) {
      // Make sure the apollo client exists
      if (apolloClient && state.selectedVessel) {
        apolloClient
          .query({
            query: DATA_ITEM_NAMES_BY_VESSEL,
            variables: {
              vessel: state.selectedVessel.name
            },
            fetchPolicy: 'network-only'
          })
          .then((res) => {
            // Create a temporary array
            const tempDataItemNameArray = []
            // Check to make sure something came back
            if (res && res.data && res.data.dataItemNamesByVessel) {
              res.data.dataItemNamesByVessel.forEach((dataItemName) => {
                // Create a new data item object
                const newDataItem = {
                  id: dataItemName.id,
                  vessel: dataItemName.vessel,
                  server: dataItemName.server,
                  item_name: dataItemName.item_name,
                  alias: dataItemName.alias,
                  inTable: false,
                  onChart: false
                }
                tempDataItemNameArray.push(newDataItem)
              })
              commit('SET_AVAILABLE_DATA_ITEM_NAMES', tempDataItemNameArray)
            }
          })
      }
    },
    syncDataWithTableAndChart({ commit }) {
      commit('SYNC_DATA_WITH_TABLE_AND_CHART')
    },
    cleanOutOldData({ state, commit }) {
      commit('CLEAN_OUT_OLD_DATA')
    },
    clearData({ commit }) {
      commit('CLEAR_DATA')
    },
    updateVessel({ state, commit, dispatch }, vessel) {
      if (apolloClient) {
        apolloClient
          .mutate({
            mutation: UPDATE_VESSEL,
            variables: {
              id: vessel.id,
              name: vessel.name,
              iconUrl: vessel.icon_url,
              pictureUrl: vessel.picture_url,
              screengrabSubdir: vessel.screengrab_subdir,
              rovName: vessel.rov_name,
              rovIconUrl: vessel.rov_icon_url,
              zoomMeetingNumber: vessel.zoom_meeting_number,
              zoomMeetingApiKey: vessel.zoom_meeting_api_key,
              zoomMeetingPassword: vessel.zoom_meeting_password,
              slackChannel: vessel.slack_channel
            }
          })
          .catch((error) => {
            console.error('Error trying to update vessel on server:')
            console.error(error)
          })
      }
    },

    // This method is called when we get a message from the server that a
    // vessel has been updated
    handleVesselUpdatedFromServer({ state, commit }, vessel) {
      // Commit the update to the store
      commit('UPDATE_VESSEL', vessel)

      // And then check to see if it's the one that is selected and update
      // the local storage too
      if (state.selectedVessel.id === vessel.id) {
        localStorage.setItem('selectedVessel', JSON.stringify(vessel))
      }
    },

    // This method handles new data from the server
    handleDataItemUpdatedFromServer({ state, commit }, dataItem) {
      commit('UPDATE_DATA_ITEM', dataItem)
    },

    handleSlackMessageSentFromServer({ state, commit }, message) {
      // Check to make sure the event has a client_msg_id, if not create one
      // using the user ID and timestamp
      if (message.client_msg_id === null) {
        message.client_msg_id = message.user + '-' + message.ts
      }

      // Make sure the message is from the channel associated with the selected vessel
      if (message.channel === state.selectedVessel.slack_channel) {
        commit('ADD_SLACK_MESSAGE', message)
      }
    },

    sendSlackMessage({ state }, message) {
      if (apolloClient) {
        apolloClient
          .mutate({
            mutation: SEND_SLACK_MESSAGE,
            variables: {
              message: message,
              channel: state.selectedVessel.slack_channel,
              client_msg_id: 'test-id'
            }
          })
          .then((data) => {
            // Result
            console.log(data)
          })
          .catch((error) => {
            // Error
            console.error(error)
          })
      }
    }
  },
  modules: {},
  getters: {}
})

export default store
