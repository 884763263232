<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <div style="text-align: center">
        <v-btn large color="primary" v-bind="attrs" v-on="on">
          Configure Data
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title>Select Data</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px">
        <v-data-table
          :headers="headers"
          :items="availableDataItems"
          :itemsPerPage="itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:item.inTable="{ item }">
            <v-checkbox
              @change="syncDataWithTableAndChart()"
              v-model="item.inTable"
            ></v-checkbox>
          </template>
          <template v-slot:item.onChart="{ item }">
            <v-checkbox
              @change="syncDataWithTableAndChart()"
              v-model="item.onChart"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'data-configure-dialog-button',
  computed: {
    ...mapState({
      availableDataItems: 'availableDataItems'
    })
  },
  data: () => ({
    dialog: false,
    itemsPerPage: 5,
    headers: [
      {
        text: 'Data Item',
        align: 'start',
        sortable: false,
        value: 'item_name'
      },
      { text: 'Server', value: 'server' },
      { text: 'In Table', value: 'inTable' },
      { text: 'On Chart', value: 'onChart' }
    ]
  }),
  methods: {
    ...mapActions({
      syncDataWithTableAndChart: 'syncDataWithTableAndChart'
    }),
    checkBoxLabel: function (dataItem) {
      let itemName = dataItem.item_name
      if (dataItem.alias) {
        itemName = dataItem.alias
      }
      return itemName + ' (' + dataItem.server + ')'
    }
  }
}
</script>
