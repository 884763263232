<template>
  <v-menu v-if="loggedIn" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mx-2" fab small color="secondary" @click="openHelp">
        <v-icon> mdi-help </v-icon>
      </v-btn>
      <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer"
        ><img :src="user.picture_url" :alt="user.username"
      /></v-avatar>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title style="cursor: pointer" @click="logout"
          >Logout</v-list-item-title
        >
      </v-list-item>
      <v-list-item v-if="user.is_admin">
        <v-list-item-title style="cursor: pointer" @click.stop="editVessel"
          >Edit Vessel</v-list-item-title
        >
      </v-list-item>
      <v-dialog v-model="editVesselDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Vessel Information</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Vessel Name"
                    disabled
                    v-model="vesselForEdit.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="ROV Name"
                    v-model="vesselForEdit.rov_name"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Icon URL"
                    v-model="vesselForEdit.icon_url"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ROV Icon URL"
                    v-model="vesselForEdit.rov_icon_url"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    label="Picture URL"
                    v-model="vesselForEdit.picture_url"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Screengrab Subdir"
                    v-model="vesselForEdit.screengrab_subdir"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Zoom Meeting Number"
                    v-model="vesselForEdit.zoom_meeting_number"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Zoom Meeting Password"
                    v-model="vesselForEdit.zoom_meeting_password"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Zoom API Key"
                    v-model="vesselForEdit.zoom_meeting_api_key"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Slack Channel"
                    v-model="vesselForEdit.slack_channel"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editVesselDialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateVessel">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'user-menu',
  data: function () {
    return {
      editVesselDialog: false,
      vesselForEdit: {}
    }
  },
  computed: {
    ...mapState({
      loggedIn: 'loggedIn',
      user: 'user',
      selectedVessel: 'selectedVessel'
    })
  },
  methods: {
    openHelp: function () {
      window.open(
        'https://docs.mbari.org/telepresence-app-docs/user-guide/',
        '_blank'
      )
    },
    logout: function () {
      this.$store.dispatch('logout')
    },
    editVessel: function () {
      // This method gets called when the user clicks on the edit vessel menu item
      // Load the properties that we are editing from the selected vessel in the store
      this.vesselForEdit = {
        id: this.selectedVessel.id,
        name: this.selectedVessel.name,
        icon_url: this.selectedVessel.icon_url,
        picture_url: this.selectedVessel.picture_url,
        screengrab_subdir: this.selectedVessel.screengrab_subdir,
        rov_name: this.selectedVessel.rov_name,
        rov_icon_url: this.selectedVessel.rov_icon_url,
        zoom_meeting_api_key: this.selectedVessel.zoom_meeting_api_key,
        zoom_meeting_number: this.selectedVessel.zoom_meeting_number,
        zoom_meeting_password: this.selectedVessel.zoom_meeting_password,
        slack_channel: this.selectedVessel.slack_channel
      }

      // Now open the dialog
      this.editVesselDialog = true
    },
    updateVessel: function () {
      console.log('updateVessel')
      this.$store.dispatch('updateVessel', this.vesselForEdit)
      this.editVesselDialog = false
    }
  }
}
</script>
<style scoped></style>
