<template>
  <div id="zoomDiv">
    <v-card
      id="zoomBanner"
      v-if="!meetingIsLive"
      class="mx-auto"
      :height="zoomBannerHeight"
      :max-height="zoomBannerHeight"
    >
      <v-img
        class="white--text align-end"
        :height="zoomBannerImageHeight"
        :src="selectedVessel.picture_url"
      >
        <v-card-title>{{ selectedVessel.name }}</v-card-title>
      </v-img>

      <v-card-subtitle class="pb-0"> Broadcast Offline </v-card-subtitle>

      <v-card-text class="text--primary">
        <div>
          The meeting will start automatically when the vessel begins
          broadcasting
        </div>
        <div>
          {{ zoomBannerMessage }}
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-show="meetingIsLive"
      :height="zoomHeight"
      :max-height="zoomHeight"
    >
      <div id="meetingSDKElement"></div>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'

// These are handles to the various divs in the zoom window we will need to work with
export default {
  name: 'zoom-meeting-panel',
  data: function () {
    return {
      client: null,
      signature: '',
      meetingIsLive: false,
      tryingToReconnect: false,
      zoomHeight: '600px',
      zoomBannerImageHeight: '500px',
      zoomBannerHeight: '600px',
      zoomBannerMessage: 'Waiting',
      sdkKey: '',
      meetingNumber: '',
      password: '',
      role: 0,
      signatureEndpoint: '/api/v1/rest/zoom-signature',
      meetingSDKElement: null,
      mainZoomWindow: null,
      roundedZoomWindow: null,
      // seeAppsPopup: null,
      mainVideoPanel: null,
      speakerTabPanel: null,
      screenShareDivContainer: null,
      screenShareDiv: null,
      screenShareVideo: null,
      screenShareControlBar: null,
      screenShareCanvas: null,
      speakerCamera: null,
      speakerCameraCanvas: null,
      speakerCameraVideo: null,
      speakerCameraListItem: null,
      speakerCameraNameDiv: null,
      speakerCameraImage: null
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      selectedVessel: 'selectedVessel'
    })
  },
  created: function () {
    this.logClientInfo()
    // Attach method to before unloaded event so that we can leave the meeting if it's going
    window.addEventListener('beforeunload', this.unloadHandler)

    // Add a handler for promises that get rejected but are note handled
    window.addEventListener(
      'unhandledrejection',
      this.unhandledRejectionHandler
    )
    // Assign a handler to resizing
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted: function () {
    // Read the selected vessel from the store and assign locally
    if (this.selectedVessel) {
      console.log('There is a vessel selected, will set meeting info to:')
      console.log('Meeting number: ' + this.selectedVessel.zoom_meeting_number)
      console.log('Password: ' + this.selectedVessel.zoom_meeting_password)
      console.log('SDK Key: ' + this.selectedVessel.zoom_meeting_api_key)
      this.meetingNumber = this.selectedVessel.zoom_meeting_number
      this.password = this.selectedVessel.zoom_meeting_password
      this.sdkKey = this.selectedVessel.zoom_meeting_api_key
    }

    // Grab thet meeting SDK div element
    this.meetingSDKElement = document.getElementById('meetingSDKElement')
    const meetingSDKObserverConfig = {
      attributes: true,
      childList: true,
      subtree: true
    }
    // Attach a mutation handler that watches for changes on zoom div and it's children
    const meetingSDKElementMutationObserver = new MutationObserver(
      this.meetingSDKElementMutationCallback
    )
    meetingSDKElementMutationObserver.observe(
      this.meetingSDKElement,
      meetingSDKObserverConfig
    )

    // Meetings should always exist if there is a selected vessel, so connect to the meeting
    this.connectToMeeting()
  },
  methods: {
    logClientInfo: function () {
      if (this.client) {
        // console.log('Client:')
        // console.log(this.client)
        // console.log('Client.getCurrentMeetingInfo:')
        // console.log(this.client.getCurrentMeetingInfo())
        // console.log('Client.getAttendeesList')
        // console.log(this.client.getAttendeeslist())
        // console.log('Client.getCurrentUserInfo')
        // console.log(this.client.getCurrentUser())
      } else {
        console.log('Client is null')
      }
    },
    unloadHandler: function (event) {
      // This means the user either close the tab/page or reloaded it, essentially it means
      // I need to have the user leave the meeting (if there is one)
      if (this.client && this.client.getCurrentMeetingInfo().isInMeeting) {
        this.client.leaveMeeting()
      }
    },
    unhandledRejectionHandler: function (event) {
      // console.log('Unhandled rejection:')
      // console.log(event)
      // console.log(event.reason)
      this.logClientInfo()
      // Check to see if we have a reason to see what we should do next
      if (event && event.reason) {
        // If the meeting was closed, just try to reconnect
        if (
          event.reason.type === 'IMPROPER_MEETING_STATE' &&
          event.reason.reason === 'closed'
        ) {
          console.log('Meeting was closed, but will just sit and wait')
          this.connectToMeeting()
        }

        // If the signature expired, try to reconnect which will get a new one
        if (
          event.reason.type === 'JOIN_MEETING_FAILED' &&
          event.reason.reason === 'The signature has expired.' &&
          event.reason.errorCode === 3705
        ) {
          console.log('Signature expired, reconnecting')
          this.connectToMeeting()
        }
      }
    },
    handleWindowResize: function () {
      this.resizeZoomFrame()
    },
    meetingSDKElementMutationCallback: function (mutationsList, observer) {
      let grabElementCalled = false
      let resizeZoomFrameCalled = false
      for (const mutation of mutationsList) {
        if (!grabElementCalled && mutation.type === 'childList') {
          this.grabElements()
          grabElementCalled = true
        } else if (!resizeZoomFrameCalled && mutation.type === 'attributes') {
          this.resizeZoomFrame()
          resizeZoomFrameCalled = true
        }
      }
    },
    connectToMeeting: function () {
      // console.log('connectToMeeting called')
      // console.log('SDK key: ' + this.sdkKey)
      // console.log('Meeting number: ' + this.meetingNumber)
      // console.log('Password: ' + this.password)
      // console.log('Role: ' + this.role)
      // console.log('Username: ' + this.user.username)
      // console.log('Email: ' + this.user.email)
      // Let's first make sure we have enough info to connect to the meeting
      if (
        this.sdkKey &&
        this.meetingNumber &&
        this.password &&
        this.role >= 0 &&
        this.user &&
        this.user.username &&
        this.user.email
      ) {
        console.log('We have enough information to connect to the meeting')
        // Check to make sure we are not in the middle of trying already
        if (!this.tryingToReconnect) {
          // Grab handle to the scope
          const me = this

          // Set the flag to say we are trying to reconnect
          me.tryingToReconnect = true

          // First check to see if there is a client already and there is a meeting in progress
          if (me.client && me.client.getCurrentMeetingInfo().isInMeeting) {
            console.log(
              'Client already exists and there is a meeting in progress'
            )
            // If there is a meeting in progress, let's leave it
            me.client.leaveMeeting()
          }

          // Then, let's get the signature from the server
          axios
            .post(me.signatureEndpoint, {
              meetingNumber: me.meetingNumber,
              role: me.role
            })
            .then((res) => {
              console.log('Got signature')
              console.log(res.data.signature)
              // Set it locally
              me.signature = res.data.signature

              // Create the client
              if (!me.client) {
                me.client = ZoomMtgEmbedded.createClient()

                // Now call init on the client
                me.client
                  .init({
                    debug: true,
                    zoomAppRoot: me.meetingSDKElement,
                    language: 'en-US',
                    customize: {
                      video: {
                        isResizable: false,
                        popper: {
                          disableDraggable: true
                        }
                      }
                    }
                  })
                  .then(() => {
                    console.log('After client init')
                    me.logClientInfo()
                    me.client
                      .join({
                        sdkKey: me.sdkKey,
                        signature: me.signature,
                        meetingNumber: me.meetingNumber,
                        password: me.password,
                        userName: me.user.username,
                        userEmail: me.user.email
                      })
                      .then(() => {
                        console.log('Joined meeting')
                        // All the zoom element should now be in place, grab references to them all
                        me.logClientInfo()
                        me.tryingToReconnect = false

                        // Let's check to see if the host is in the meeting
                        if (me.checkIfHostInMeeting()) {
                          me.meetingIsLive = true
                        }
                      })
                      .catch((err) => {
                        console.error('Error trying to join meeting')
                        console.error(err)
                        me.logClientInfo()
                        me.tryingToReconnect = false
                      })
                  })
                  .catch((error) => {
                    console.error('Zoom client init error:')
                    console.error(error)
                    me.logClientInfo()
                    me.tryingToReconnect = false
                  })
              } else {
                me.client
                  .join({
                    sdkKey: me.sdkKey,
                    signature: me.signature,
                    meetingNumber: me.meetingNumber,
                    password: me.password,
                    userName: me.user.username,
                    userEmail: me.user.email
                  })
                  .then(() => {
                    me.logClientInfo()
                    me.tryingToReconnect = false
                    // Let's check to see if the host is in the meeting
                    if (me.checkIfHostInMeeting()) {
                      me.meetingIsLive = true
                    }
                  })
                  .catch((err) => {
                    console.error('Error trying to join meeting')
                    console.error(err)
                    me.logClientInfo()
                    me.tryingToReconnect = false
                  })
              }
            })
            .catch((error) => {
              console.error('Error trying to get signature from server')
              console.error(error)
              me.logClientInfo()
              me.tryingToReconnect = false
            })
        } else {
          console.log('We are already trying to reconnect, will do nothing')
        }
      } else {
        console.log('Not enough info to connect to the meeting')
      }
    },
    checkIfHostInMeeting: function () {
      // The flag to return
      let isHostInMeeting = false

      // Check to see if the client exists
      if (
        this.client &&
        this.client.getCurrentMeetingInfo() &&
        this.client.getCurrentMeetingInfo().isInMeeting
      ) {
        // Get the list of users and loop over to see if the host is in the meeting
        const users = this.client.getAttendeeslist()
        users.forEach((user) => {
          if (user.isHost) {
            isHostInMeeting = true
          }
        })
      }
      return isHostInMeeting
    },
    grabElements: function () {
      // Make sure the handle points to the zoom window if one exists or is null
      if (this.meetingSDKElement.children[0]) {
        this.mainZoomWindow = this.meetingSDKElement.children[0]
        this.mainZoomWindow.style.width = '100%'
        this.mainZoomWindow.style.height = '100%'
      } else {
        this.mainZoomWindow = null
      }

      // Same for the rounded window
      if (this.mainZoomWindow && this.mainZoomWindow.children[0]) {
        this.roundedZoomWindow = this.mainZoomWindow.children[0]
        this.roundedZoomWindow.style.width = '100%'
        this.roundedZoomWindow.style.height = '100%'
      } else {
        this.roundedZoomWindow = null
      }

      // Same for the mainVideoPanel
      if (this.roundedZoomWindow && this.roundedZoomWindow.children[0]) {
        this.mainVideoPanel = this.roundedZoomWindow.children[0]
        this.mainVideoPanel.style.width = '100%'
        this.mainVideoPanel.style.height = '92%'
      } else {
        this.mainVideoPanel = null
      }

      // Same for the speakerTabPanel
      if (this.mainVideoPanel && this.mainVideoPanel.children[2]) {
        this.speakerTabPanel = this.mainVideoPanel.children[2]
        this.speakerTabPanel.style.width = '100%'
        this.speakerTabPanel.style.height = '100%'
      } else {
        this.speakerTabPanel = null
      }

      // Same for the screenShareDivContainer
      if (this.speakerTabPanel && this.speakerTabPanel.children[0]) {
        this.screenShareDivContainer = this.speakerTabPanel.children[0]
        // this.screenShareDivContainer.style.width = '100%'
        // this.screenShareDivContainer.style.height = '100%'
      } else {
        this.screenShareDivContainer = null
      }

      // Same for the screenShareDiv
      if (
        this.screenShareDivContainer &&
        this.screenShareDivContainer.children[0]
      ) {
        this.screenShareDiv = this.screenShareDivContainer.children[0]
        this.screenShareDiv.style.width = '100%'
      } else {
        this.screenShareDiv = null
      }

      // And for screenShareVideo
      if (this.screenShareDiv && this.screenShareDiv.children[0]) {
        this.screenShareVideo = this.screenShareDiv.children[0]
        this.screenShareVideo.style.width = '100%'
      } else {
        this.screenShareVideo = null
      }

      // And for screenShareControlBar
      if (this.screenShareDiv && this.screenShareDiv.children[1]) {
        this.screenShareControlBar = this.screenShareDiv.children[1]
        // this.screenShareControlBar.style.width = '100%'
      } else {
        this.screenShareControlBar = null
      }

      // And for screenShareCanvas
      if (this.screenShareVideo && this.screenShareVideo.children[0]) {
        this.screenShareCanvas = this.screenShareVideo.children[0]
        // this.screenShareCanvas.style.width = '100%'
      } else {
        this.screenShareCanvas = null
      }

      // And for speakerCamera
      if (this.screenShareVideo && this.screenShareDivContainer.children[1]) {
        this.speakerCamera = this.screenShareDivContainer.children[1]
        // this.speakerCamera.style.width = '100%'
      } else {
        this.speakerCamera = null
      }

      // And for speakerCameraCanvas
      if (this.speakerCamera && this.speakerCamera.children[0]) {
        this.speakerCameraCanvas = this.speakerCamera.children[0]
        // this.speakerCameraCanvas.style.width = '100%'
      } else {
        this.speakerCameraCanvas = null
      }

      // And for speakerCameraVideo
      if (this.speakerCamera && this.speakerCamera.children[1]) {
        this.speakerCameraVideo = this.speakerCamera.children[1]
        // this.speakerCameraVideo.style.width = '100%'
      } else {
        this.speakerCameraVideo = null
      }

      // And for speakerCameraListItem
      if (this.speakerCamera && this.speakerCamera.children[2]) {
        this.speakerCameraListItem = this.speakerCamera.children[2]
        // this.speakerCameraListItem.style.width = '100%'
      } else {
        this.speakerCameraListItem = null
      }
      // And for speakerCameraNameDiv
      if (
        this.speakerCameraListItem &&
        this.speakerCameraListItem.children[0]
      ) {
        this.speakerCameraNameDiv = this.speakerCameraListItem.children[0]
      } else {
        this.speakerCameraNameDiv = null
      }

      // And for speakerCameraImage
      if (
        this.speakerCameraListItem &&
        this.speakerCameraListItem.children[1]
      ) {
        this.speakerCameraImage = this.speakerCameraListItem.children[1]
        // this.speakerCameraImage.style.height = '100%'
      } else {
        this.speakerCameraImage = null
      }

      // seeAppsPopup =
      //   roundedZoomWindow.children[0].children[0].children[0].children[1]
      // if (seeAppsPopup) {
      //   seeAppsPopup.setAttribute('aria-label', '')
      //   seeAppsPopup.setAttribute('title', '')
      // }
      // console.log('seeAppsPopup: ')
      // console.log(seeAppsPopup)
    },
    resizeZoomFrame: function () {
      // First let's see if the host is in the meeting
      const isHostInMeeting = this.checkIfHostInMeeting()
      if (isHostInMeeting) {
        this.meetingIsLive = true
      } else {
        this.meetingIsLive = false
      }
      // Check if the screen sharing control bar is visible
      if (this.screenShareControlBar) {
        // Looks like we are in screen sharing mode, what is the current size of the screen share video?
        if (this.screenShareVideo && this.screenShareCanvas) {
          // Adjust sizes
          const screenShareVideoHeight = this.screenShareVideo.clientHeight
          this.zoomHeight = screenShareVideoHeight + 100
          if (this.speakerCamera) {
            this.speakerCamera.style.visibility = 'hidden'
            this.speakerCamera.style.width = '0px'
          }
        }
      } else {
        if (this.screenShareDivContainer) {
          this.screenShareDivContainer.style.height = '100%'
        }
        if (this.speakerCamera) {
          this.zoomHeight = '600px'
          this.speakerCamera.style.visibility = 'visible'
          this.speakerCamera.style.width = '100%'
        }
      }
    }
  },
  watch: {
    selectedVessel: function (newVessel) {
      console.log('Selected vessel changed')
      console.log(this.selectedVessel)
      // Set a flag to indicate that a new meeting is needed
      let newMeetingNeeded = false

      // First make sure there is a selected vessel
      if (this.selectedVessel) {
        // OK, there is a selected vessel, let see if the meeting number is
        // different or not defined locally, that would indicate a new meeting is necessary
        if (
          (!this.meetingNumber && this.selectedVessel.zoom_meeting_number) ||
          this.meetingNumber !== this.selectedVessel.zoom_meeting_number
        ) {
          newMeetingNeeded = true
        }
      } else {
        if (this.client && this.client.getCurrentMeetingInfo().isInMeeting) {
          this.client.leaveMeeting()
        }
      }

      // If a new meeting is needed, then we need to configure the client
      if (newMeetingNeeded) {
        // Set the local variables to match the selected vessel and join the meeting
        this.meetingNumber = this.selectedVessel.zoom_meeting_number
        this.password = this.selectedVessel.zoom_meeting_password
        this.sdkKey = this.selectedVessel.zoom_meeting_api_key
        this.connectToMeeting()
      }
    }
  }
}
</script>
<style scoped>
#meetingSDKElement {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  left: 0 !important;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: unset !important;
}
</style>
