var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Configure Data ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Data")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"500px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.availableDataItems,"itemsPerPage":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.inTable",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.syncDataWithTableAndChart()}},model:{value:(item.inTable),callback:function ($$v) {_vm.$set(item, "inTable", $$v)},expression:"item.inTable"}})]}},{key:"item.onChart",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.syncDataWithTableAndChart()}},model:{value:(item.onChart),callback:function ($$v) {_vm.$set(item, "onChart", $$v)},expression:"item.onChart"}})]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }