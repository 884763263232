<template>
  <v-carousel height="100%">
    <v-carousel-item
      v-for="(screengrab, i) in screengrabs"
      :key="i"
      :href="screengrab"
      eager
      target="_blank"
    >
      <v-img
        eager
        max-height="100%"
        height="100%"
        aspect-ratio="1.7778"
        contain
        :src="screengrab"
        :alt="screengrab"
      />
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'screen-grab-panel',
  computed: {
    ...mapState({
      screengrabs: 'screengrabs'
    })
  }
}
</script>
<style scoped></style>
